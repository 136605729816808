import React, { useEffect, useState } from 'react';
import './ViewOrder.css';
import { Container } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

const ViewOrder = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const selectedProducts = useSelector(state => state.selectedProducts);
  const [allSum, setAllSum] = useState(0);
  const userId = useSelector(state => state.userid);

  /* eslint-disable */
  useEffect(() => {
    let sum = selectedProducts
      .filter(p => p.num > 0)
      .reduce((t, cv) => {
        return t + cv.details.unit_price * cv.num;
      }, 0);

    setAllSum(sum);
  }, []);
  /* eslint-enable */

  const handleOrderPay = async () => {
    // console.log(window.Telegram.WebApp.initDataUnsafe)
    try {
      // const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
      const cart = await axios.post(`${backendUrl}/carts/`, {
        telegram_id: userId,
      });

      async function fetchSequentially(sP, c) {
        const promises = sP.map(item =>
          axios
            .post(`${backendUrl}/carts/${c}/items/`, {
              product_id: item.details.id,
              quantity: item.num,
            })
            .catch(error => {
              console.error(`Failed to fetch ${item}:`, error);
            })
        );

        return Promise.all(promises);
      }

      await fetchSequentially(selectedProducts || [], cart.data.id);

      await axios.get(`${backendUrl}/close-webapp/${userId}`);

      await window.Telegram.WebApp.close();
    } catch (error) {
      // setCons(error.message)
      console.log(error);
      // window.Telegram.WebApp.close();
    }
  };

  return (
    <div className="view-order-card">
      <Container style={{ minHeight: '92vh' }}>
        <div className="view-order-header">
          <div>Ваши заказы</div>

          <div>
            <NavLink to={`/${userId}`} onClick={() => navigate(-1)}>
              Изменить
            </NavLink>
          </div>
        </div>

        {selectedProducts.length === 0 && (
          <div>
            <h5>Вы не выбрали товар!</h5>
            <NavLink to={`/${userId}`}>Click here!</NavLink>
          </div>
        )}

        <div className="view-order-products">
          {selectedProducts
            .filter(p => p.num > 0)
            .map(s => (
              <div className="view-order-product">
                <div className="view-order-product-left">
                  <div>
                    <img src={s.details.image} alt="" />
                  </div>

                  <div>
                    <div style={{ fontSize: '20px', fontWeight: '700' }}>
                      {s.details.title}{' '}
                      <span style={{ color: 'rgba(248, 169, 23)' }}>
                        {s.num}x
                      </span>
                    </div>
                    {/* <div>Cat Stor</div> */}
                  </div>
                </div>

                <div className="view-order-product-right">
                  ${s.details.unit_price * s.num}
                </div>
              </div>
            ))}
        </div>
      </Container>

      {selectedProducts.length > 0 && (
        <div
          className="view-order-pay"
          onClick={handleOrderPay}
          style={{ position: 'absolute', bottom: '0', width: '100%' }}
        >
          ПЛАТИТЬ <span>${allSum}</span>
        </div>
      )}
    </div>
  );
};

export default ViewOrder;

