import React, { useEffect } from 'react';
import './Navbar.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const userid = useSelector(state => state.userid);
  const navigate = useNavigate();

  return (
    <div className="my-navbar">
      <div
        style={{ position: 'absolute', top: '10px', left: '15px' }}
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          style={{
            fontSize: '22px',
            color: '#fff',
            background: 'red',
            padding: '5px 10px',
            borderRadius: '5px',
          }}
        />
      </div>
      <NavLink to={`/${userid}`}>
        <img src="/image/logo.jpg" alt="" />
      </NavLink>
    </div>
  );
};

export default Navbar;
